import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { SectionHeader } from "../../components/SectionHeader";
import { callApi } from "../../utils/fetchData";
import { formatCurrency } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  width: "500px",
  height: "300px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: theme.palette.grey[100],
  "&:hover": {
    backgroundColor: theme.palette.grey[200]
  }
}));

export const VerifyTransaction = () => {
  const { transactions } = useData();
  const { transactionId } = useParams();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const [error, setError] = useState<string>();

  const navigate = useNavigate();

  const transaction = transactions.data?.items.find(
    (t) => t.id === transactionId
  );

  const uploadFileMutation = useMutation(
    async (formData: FormData) => {
      const response = await callApi(
        `${config?.API_URL}/transactions/${transaction?.id}/verification-info/file`,
        "POST",
        formData,
        getToken
      );

      if (!response.ok) {
        let errorMessage = "There was a problem uploading your file.";

        if (response.status === 400) {
          const responseBody = await response.json();
          if (responseBody && responseBody.detail) {
            errorMessage = responseBody.detail;
          }
        }

        throw new Error(errorMessage);
      }
    },
    {
      onSuccess: () => {
        transactions.refetch();
        setError("");
      },
      onError: (error: Error) => {
        setError(error.message);
      }
    }
  );

  const updateTransactionVerificationInfoMutation = useMutation(
    async (values: { reference: string; notes: string }) => {
      const response = await callApi(
        `${config?.API_URL}/transactions/${transaction?.id}/verification-info`,
        "PUT",
        { reference: values.reference, notes: [values.notes] }, // Notes is a string array, in case we need to extend functionality further in future
        getToken
      );

      if (!response.ok)
        throw new Error(
          "There was a problem updating your transaction verification."
        );
    },
    {
      onSuccess: () => {
        setError("");
        transactions.refetch();
        navigate("/");
      },
      onError: (error: Error) => {
        setError(error.message);
      }
    }
  );

  const referenceNotesFormik = useFormik({
    initialValues: {
      reference: "",
      notes: ""
    },
    validationSchema: Yup.object({
      reference: Yup.string()
        .min(1)
        .max(50, "Reference must be 20 characters or less")
        .required("Reference is required"),
      notes: Yup.string()
        .optional()
        .min(1)
        .max(200, "Notes must be 100 characters or less")
    }),
    onSubmit: async (values) => {
      updateTransactionVerificationInfoMutation.mutate(values);
    }
  });

  useEffect(() => {
    if (
      transaction &&
      (!transaction.requireVerification ||
        transaction.verificationInfo?.isComplete)
    )
      return navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const formData = new FormData();
      formData.append("File", event.currentTarget.files[0]);
      uploadFileMutation.mutate(formData);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionHeader text="Verify Transaction" showBackButton={true} />

        {transactions.isLoading ||
        uploadFileMutation.isLoading ||
        updateTransactionVerificationInfoMutation.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Typography variant="h6" my={5}>
              {transaction?.cardAcceptor}
              {" - "}
              {formatCurrency(transaction?.billingAmount?.amount ?? 0)}
            </Typography>

            {!transaction?.verificationInfo?.verifiedTransactionAmount ? (
              <>
                <UploadBox>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept="image/jpeg,image/png,application/pdf"
                    onChange={handleFileChange}
                    style={{
                      opacity: 0,
                      width: "500px",
                      height: "300px",
                      position: "absolute",
                      cursor: "pointer"
                    }}
                  />
                  <Typography variant="h6" color="textSecondary">
                    Upload Receipt Image/PDF
                  </Typography>
                </UploadBox>
              </>
            ) : (
              <Grid container xs={12} gap={5}>
                <Grid alignItems="center" container spacing={2}>
                  <Grid item xs={8}>
                    <Typography>Verified Transaction Amount: </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      disabled={true}
                      value={formatCurrency(
                        transaction.verificationInfo.verifiedTransactionAmount
                          .amount
                      )}
                    />
                  </Grid>
                </Grid>

                <form onSubmit={referenceNotesFormik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="reference"
                        name="reference"
                        placeholder="Reference*"
                        required
                        value={referenceNotesFormik.values.reference}
                        onChange={referenceNotesFormik.handleChange}
                        error={
                          referenceNotesFormik.touched.reference &&
                          Boolean(referenceNotesFormik.errors.reference)
                        }
                        helperText={
                          referenceNotesFormik.touched.reference &&
                          referenceNotesFormik.errors.reference
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="notes"
                        name="notes"
                        placeholder="Notes*"
                        value={referenceNotesFormik.values.notes}
                        onChange={referenceNotesFormik.handleChange}
                        error={
                          referenceNotesFormik.touched.notes &&
                          Boolean(referenceNotesFormik.errors.notes)
                        }
                        helperText={
                          referenceNotesFormik.touched.notes &&
                          referenceNotesFormik.errors.notes
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </>
        )}

        {error && (
          <Typography color="error" my={2}>
            {error}. Please contact support@extraordinarypay.com.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
